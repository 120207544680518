import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
import { Questiontext4k } from "../Questiontext";

export function Longflights() {
  const { questionstate, questiondispatch } = useGetQuestion();
  const [numeros, setNumeros] = useState(Number(questionstate.longflights));
  const { gotoNext } = useMultiformcontext();
  const state = [
    { id: 1, text: "None", value: "0" },
    { id: 2, text: "Rarely (1 trip)", value: "1" },
    { id: 3, text: "Occasionally (2 trips)", value: "2" },
    { id: 4, text: "Regularly (4 trips)", value: "4" },
    { id: 5, text: "I'd like to be more specific", value: "5", isCustom: true },
  ];
  const finobjval = state[state.length - 1]?.isCustom
    ? ![...state]
        .slice(0, state.length - 1)
        .some((item) => Number(item.value) === numeros)
    : true;
  const [showcusti, setShowcusti] = useState(finobjval);
  return (
    <Box width="80%" position="absolute" top="20%">
      <Box>
        <Box>
          <Box>
            {/* <Box
          sx={{
            display: { md: "none" },
            width: { xs: "7rem", md: "10rem" },
          }}
        >
          <img
            className="fullimagewidth"
            src="https://projectwren.imgix.net/calculator-icons/cloud_plane.png?auto=format%2Ccompress&q=35"
            alt="logoimage"
          />
        </Box> */}
            <Questiontext4k>In a year, I fly International:</Questiontext4k>
            {/* <Questiontip>
          Count any flights longer than 3 hours or much greater than 1000km,
          like flying from Delhi to Mumbai.
        </Questiontip> */}
          </Box>
          <Box
            marginBottom="7rem"
            display="flex"
            flexDirection="column"
            gap="1rem"
          >
            {state.map((item) => (
              <Box
                key={item.id}
                onClick={() => {
                  setNumeros(Number(item.value));
                  questiondispatch({
                    type: "SET_LONG_FLIGHT",
                    payload: { longflights: item.value },
                  });
                  /**
               * 
              setSelected(Number(item.value));
               * onClick={() => {
              questiondispatch({
                type: "SET_LONG_FLIGHT",
                payload: { longflights: item.value },
              });
              */
                  if (item?.isCustom) {
                    setShowcusti(true);
                  } else {
                    setShowcusti(false);
                  }
                }}
                sx={{
                  // paddingX: { xs: "1rem", md: "2rem" },
                  // paddingY: { xs: "0.5rem", md: "1rem" },
                  fontSize: "2.2rem",
                  fontWeight: "lighter",
                  paddingX: "2rem",
                  paddingY: "1.2rem",
                  border: 1,
                  cursor: "pointer",
                  borderColor: "#000",
                  backgroundColor:
                    Number(questionstate.longflights) === Number(item.value) ||
                    (item?.isCustom && showcusti)
                      ? "#b3d6ff"
                      : "inherit",
                }}
              >
                {item.text}
              </Box>
            ))}
            {showcusti && (
              <Box>
                <TextField
                  autoComplete="off"
                  // value={numeros}
                  InputProps={{
                    style: {
                      fontSize: "2.2rem",
                    },
                  }}
                  value={questionstate.longflights}
                  // onChange={(event) => setNumeros(Number(event.target.value))}
                  onChange={(event) =>
                    questiondispatch({
                      type: "SET_LONG_FLIGHT",
                      payload: {
                        longflights:
                          event.target.value.length > 0
                            ? JSON.stringify(
                                Math.abs(Number(event.target.value))
                              )
                            : "0",
                      },
                    })
                  }
                  fullWidth={true}
                  label="Custom"
                  variant="filled"
                  type="number"
                  min={0}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box marginTop="10rem">
          <Button
            disabled={Number(questionstate.longflights) < 0 ? true : false}
            onClick={() => {
              gotoNext();
            }}
            type="submit"
            variant="contained"
          >
            <Typography
              fontSize="2.5rem"
              padding="0.5rem"
              sx={{
                fontWeight: 100,
              }}
            >
              Next
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
