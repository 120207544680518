import { Box, Button, Slider, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGetQuestion } from "../../context/Questioncontext";
import { Questiontext4k } from "../Questiontext";
// import { Questiontext, Questiontip } from "../Questiontext";
import { useMultiformcontext } from "../../context/Multiformcontext";

export function Homemembers() {
  const { questiondispatch, questionstate } = useGetQuestion();
  const { gotoNext } = useMultiformcontext();
  const validationSchema = yup.object({
    members: yup
      .number("Enter your members")
      .required("members is required")
      .moreThan(0)
      .lessThan(21),
  });
  const formik = useFormik({
    initialValues: {
      members: questionstate.members,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      questiondispatch({
        type: "SET_MEMBERS",
        payload: {
          members: values.members,
        },
      });
      gotoNext();
    },
  });
  return (
    <Box width="80%" position="absolute" top="20%">
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Box marginBottom="3rem">
            <Questiontext4k>
              <Typography
                fontWeight="inherit"
                fontSize="inherit"
                lineHeight="1.4"
              >
                Total Family Members (Excluding House help):
              </Typography>
            </Questiontext4k>
          </Box>
          {/* <TextField
          fullWidth
          sx={{ marginBottom: "1rem" }}
          name="members"
          type="number"
          min={1}
          max={20}
          value={formik.values.members}
          onChange={formik.handleChange}
          error={formik.touched.members && Boolean(formik.errors.members)}
          helperText={formik.touched.members && formik.errors.members}
        /> */}
          <Slider
            min={1}
            max={20}
            defaultValue={questionstate.members}
            aria-label="Default"
            value={formik.values.members}
            valueLabelDisplay="auto"
            // onChange={formik.handleChange}
            onChange={(eventj) =>
              formik.setFieldValue("members", eventj.target.value)
            }
            // error={formik.touched.members && Boolean(formik.errors.members)}
            // helperText={formik.touched.members && formik.errors.members}
            // valueLabelDisplay="auto"
          />
        </Box>
        <Box marginTop="10rem">
          <Button type="submit" variant="contained">
            <Typography
              fontSize="2.5rem"
              padding="0.5rem"
              sx={{
                fontWeight: 100,
              }}
            >
              Next
            </Typography>
          </Button>
        </Box>
      </form>
    </Box>
  );
}
