// import MenuIcon from "@mui/icons-material/Menu";
// import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Box } from "@mui/material";
// import { useState } from "react";
import hundayilogo from "../assets/images/hundayi_logo.png";
// import { Link as Mlink } from "@mui/material";

const Navbar = () => {
  // const [state, setState] = useState(false);
  return (
    <AppBar position="sticky">
      <Box
        bgcolor="primary"
        display="flex"
        justifyContent={{ xs: "center" }}
        paddingY={"1.25rem"}
        alignItems={"center"}
      >
        <Box>
          <Box>
            <Box width={{ xs: "4.9rem" }} className="logoimg">
              {/* 4.9rem */}
              <img
                style={{ width: "100%" }}
                src={hundayilogo}
                alt="hundayilogo"
              />
            </Box>
          </Box>
        </Box>
        {/* <div className="c_links">
          <Box visibility="hidden" display="flex" gap={"0.5rem"}>
            <Mlink
              underline="none"
              fontSize="1.125rem"
              target="_blank"
              rel="noopener"
              sx={{ color: "white" }}
              href="https://www.lowsoot.com"
            >
              About
            </Mlink>
            <Mlink
              underline="none"
              fontSize="1.125rem"
              target="_blank"
              rel="noopener"
              sx={{ color: "white" }}
              href="https://www.lowsoot.com"
            >
              Blog
            </Mlink>
            <Mlink
              underline="none"
              fontSize="1.125rem"
              target="_blank"
              rel="noopener"
              sx={{ color: "white" }}
              href="https://www.lowsoot.com"
            >
              Product
            </Mlink>
          </Box>
        </div>
        <div
          className="c_navtoggle"
          style={{ position: "absolute", left: "1rem" }}
        >
          <Box
            onClick={() => setState((curr) => !curr)}
            display="flex"
            visibility="hidden"
            gap={"0.5rem"}
          >
            <IconButton color="texted">
              {state ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Box>
        </div> */}
      </Box>
      {/* {state && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          paddingX={"2rem"}
          width={"100%"}
          gap={"0.5rem"}
          paddingBottom={"1rem"}
          justifyContent={"center"}
          bgcolor="primary"
          visibility="hidden"
        >
          <Mlink
            underline="none"
            fontSize="1.125rem"
            target="_blank"
            rel="noopener"
            sx={{ color: "white" }}
            href="https://www.lowsoot.com"
          >
            About
          </Mlink>
          <Mlink
            underline="none"
            fontSize="1.125rem"
            target="_blank"
            rel="noopener"
            sx={{ color: "white" }}
            href="https://www.lowsoot.com"
          >
            Blog
          </Mlink>
          <Mlink
            underline="none"
            fontSize="1.125rem"
            target="_blank"
            rel="noopener"
            sx={{ color: "white" }}
            href="https://www.lowsoot.com"
          >
            Product
          </Mlink>
        </Box>
      )} */}
    </AppBar>
  );
};

export default Navbar;
