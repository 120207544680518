import Twobuttons from "../components/Twobuttons";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
// import hundayi1 from "../assets/images/hyndayipic1.jpg";
// import hundayi2 from "../assets/images/hyndayipic2.jpg";
import { useMultiformcontext } from "../context/Multiformcontext";
// import { Sidebar } from "../components/Sidebar";

export function Calcinput() {
  const { state, gotoPrev, gotoNext, lengthkeys } = useMultiformcontext();
  const { comps, currentkey } = useMultiformcontext();
  const Currentcomp = comps?.[currentkey];
  // const gttt = state + 1 === Object.keys(comps).length ? hundayi1 : hundayi2;
  // console.log(Object.keys(comps).length, state + 1);
  return (
    <>
      <Container>
        {/* <p>
          {state} {Object.keys(comps).length - 3}
        </p> */}
        {/* <p>{state + 1 > Object.keys(comps).length - 2 ? "true" : "false"}</p> */}
        {/*  #002c5f */}
        <Box width="100%" height="100%" position="relative">
          {/* {state + 1 > Object.keys(comps).length - 2 && (
            <Box
              zIndex={-1}
              top="50%"
              left="50%"
              position="absolute"
              height="100%"
              width="100%"
              sx={{ transform: "translate(-50%, -50%)" }}
            >
              <img
                width="100%"
                height="100%"
                // src="https://img.freepik.com/premium-vector/abstract-dark-blue-with-curve-lines-background-vector-illustration_518299-621.jpg?w=996"
                src={gttt}
                alt=""
              />
            </Box>
          )} */}
          {/* {state + 1 === 1 && (
            <Box
              zIndex={-1}
              top="50%"
              left="50%"
              position="absolute"
              height="100%"
              width="100%"
              sx={{ transform: "translate(-50%, -50%)" }}
            >
              <img
                width="100%"
                height="100%"
                // src="https://img.freepik.com/premium-vector/abstract-dark-blue-with-curve-lines-background-vector-illustration_518299-621.jpg?w=996"
                src={gttt}
                alt=""
              />
            </Box>
          )} */}
          {/* {state + 1 === 1 && (
            <Box
              zIndex={-1}
              top="50%"
              left="50%"
              position="absolute"
              height="80vh"
              width="100%"
              sx={{ transform: "translate(-50%, -50%)" }}
            >
              <img width="100%" height="100%" src={hundayi1} alt="" />
            </Box>
          )} */}
          <Box
            sx={
              {
                // display: { xs: "block", md: "grid" },
                // gridTemplateColumns: "1fr 1fr 1fr",
              }
            }
          >
            {/* <Sidebar /> */}
            <Box
              // marginTop="5rem"
              // bgcolor="cadetblue"
              height="80vh"
              display="flex"
              alignItems="center"
              justifyContent="center"
              // marginBottom="8rem"
              maxWidth="90%"
              marginX="auto"
              // paddingX="2rem"
            >
              <Currentcomp />
            </Box>
            {/* <Box /> */}
          </Box>
        </Box>
      </Container>
      <Twobuttons
        gotoNext={gotoNext}
        gotoPrev={gotoPrev}
        state={state}
        lengthkeys={lengthkeys}
      />
    </>
  );
}
