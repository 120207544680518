import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
// import { Subquestiontext, Questiontext } from "../Questiontext";
// import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import { Conftextsuggestion, Confttext3, Confttext2 } from "../Questiontext";
import { useEffect, useState } from "react";
import axios from "axios";
export function Confirmation2() {
  const { gotoNext } = useMultiformcontext();
  const storevals = useGetQuestion();
  const [text, setText] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;
    (async function () {
      try {
        setLoader(true);
        const response = await axios.post(
          "https://hundayi-calculator.yogeshpendse.repl.co/totaldiet",
          {
            diet: storevals.questionstate.diet,
          },
          { signal: signal }
        );
        setLoader(false);
        setText(response.data.totaldiet);
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message);
        } else {
          console.log(error);
        }
      } finally {
        setLoader(false);
      }
    })();
    return () => {
      setLoader(false);
      controller.abort();
    };
  }, [storevals.questionstate.diet]);
  if (loader) {
    return (
      <>
        <CircularProgress />
      </>
    );
  }
  return (
    <>
      <Box position="absolute" top="20%">
        <Box
          marginBottom="3rem"
          // marginBottom={{ xs: "1.2rem", md: "2rem" }}
          fontWeight="300"
        >
          {storevals?.questionstate?.diet === "nonveg" && (
            <Confttext2>Dear {storevals?.questionstate?.nameval},</Confttext2>
          )}
          {storevals?.questionstate?.diet === "nonveg" && (
            <Confttext2 marginBottom="3rem" fontSize="3rem">
              We hope that some change in your food habits can bring down your
              emissions.
            </Confttext2>
          )}
          {storevals?.questionstate?.diet === "veg" && (
            <Confttext2 marginBottom="3rem" fontSize="3rem">
              Good job! Your food habits are appreciable.
            </Confttext2>
          )}
          {storevals?.questionstate?.diet === "vegan" && (
            <Confttext2 marginBottom="3rem" fontSize="3rem">
              Good job! Your food habits are appreciable.
            </Confttext2>
          )}
        </Box>
        <Box
          marginBottom="3rem"
          // marginBottom={{ xs: "1.2rem", md: "3rem" }}
        >
          <Confttext3 fontSize="4.8rem" lineHeight="5rem">
            Your emissions for the category food are:
          </Confttext3>
          <Confttext3 fontSize="4.8rem" lineHeight="5rem">
            {Number(text).toFixed(2)} Kgs of CO<sub>2</sub>e/month
          </Confttext3>
        </Box>
        <Box marginBottom="20%">
          {/* <Box
            position="relative"
            color="black"
            padding="0.5rem"
            boxShadow="0 0 0 0.1rem #c4c4c7"
            borderRadius="0.1rem"
            //   sx={{ boxShadow: "0 0 0 1rem black" }}
          >
            <Icon
              sx={{
                position: "absolute",
                top: "-1.4rem",
                left: "-0.5rem",
                transform: "rotate(180deg)",
              }}
            >
              <WbIncandescentIcon color="grayth" />
            </Icon>
            <Typography fontSize="2.5rem"> */}
          <Conftextsuggestion>
            Choosing more vegan or vegetarian options can help lower your
            footprint.
          </Conftextsuggestion>
          {/* </Typography>
          </Box> */}
        </Box>
        <Box>
          <Button
            onClick={() => {
              gotoNext();
            }}
            type="submit"
            variant="contained"
          >
            <Typography
              fontSize="2.5rem"
              padding="0.5rem"
              sx={{
                fontWeight: 100,
              }}
            >
              Next up - Home
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}
