import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useMultiformcontext } from "../../context/Multiformcontext";
// import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
import { Questiontext4k } from "../Questiontext";

export function Electricityconsumtion() {
  // const [numeros, setNumeros] = useState(7);
  const { questionstate, questiondispatch } = useGetQuestion();
  const { gotoNext } = useMultiformcontext();
  const state = [
    { id: 1, text: "Little - minimal usages (50 Kwh/units)", value: "50" },
    {
      id: 2,
      text: "Average - my AC/heater is on sometimes in a day (100 Kwh/units)",
      value: "100",
    },
    {
      id: 3,
      text: "A lot - my AC/heater is on most of the time (300 Kwh/units)",
      value: "300",
    },
    {
      id: 4,
      text: "I'd like to be more specific",
      value: "10",
      isCustom: true,
    },
  ];
  const finobjval = state[state.length - 1]?.isCustom
    ? ![...state]
        .slice(0, state.length - 1)
        .some(
          (item) =>
            Number(item.value) === Number(questionstate.electricityconsumtion)
        )
    : true;
  const [showcusti, setShowcusti] = useState(finobjval);
  return (
    <Box position="absolute" top="20%">
      <Box>
        <Box>
          {/* <Box
          sx={{
            display: { md: "none" },
            width: { xs: "7rem", md: "10rem" },
          }}
        >
          <img
            className="fullimagewidth"
            src="https://projectwren.imgix.net/calculator-icons/cloud_battery.png?auto=format%2Ccompress&q=35"
            alt="logoimage"
          />
        </Box> */}
          <Questiontext4k>
            My monthly electricity consumption is:
          </Questiontext4k>
        </Box>
        <Box
          marginBottom="7rem"
          display="flex"
          flexDirection="column"
          gap="1rem"
        >
          {state.map((item) => (
            <Box
              key={item.id}
              onClick={() => {
                // setNumeros(item.value);
                questiondispatch({
                  type: "SET_ELECTRICITY_CONSUMPTION",
                  payload: { electricityconsumtion: item.value },
                });
                if (item?.isCustom) {
                  setShowcusti(true);
                } else {
                  setShowcusti(false);
                }
              }}
              sx={{
                fontSize: "2.2rem",
                fontWeight: "lighter",
                paddingX: "2rem",
                paddingY: "1.2rem",
                // paddingX: { xs: "1rem", md: "2rem" },
                // paddingY: { xs: "0.5rem", md: "1rem" },
                border: 1,
                cursor: "pointer",
                borderColor: "#000",
                backgroundColor:
                  Number(questionstate.electricityconsumtion) ===
                    Number(item.value) ||
                  (item?.isCustom && showcusti)
                    ? "#b3d6ff"
                    : "inherit",
              }}
            >
              {item.text}
            </Box>
          ))}
          {showcusti && (
            <Box>
              <TextField
                autoComplete="off"
                value={questionstate.electricityconsumtion}
                // onChange={(event) => setNumeros(Number(event.target.value))}
                onChange={(event) =>
                  questiondispatch({
                    type: "SET_ELECTRICITY_CONSUMPTION",
                    payload: {
                      electricityconsumtion:
                        event.target.value.length > 0
                          ? JSON.stringify(Math.abs(Number(event.target.value)))
                          : "1",
                    },
                  })
                }
                // fullWidth={
                //   Number(questionstate.electricityconsumtion) < 1 ? true : false
                // }
                fullWidth
                label="Custom"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Kwh/units</InputAdornment>
                  ),
                  style: {
                    fontSize: "2.2rem",
                  },
                }}
                variant="filled"
                type="number"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box marginBottom="10rem">
        <Button
          disabled={questionstate.electricityconsumtion < 1 ? true : false}
          onClick={() => {
            gotoNext();
          }}
          type="submit"
          variant="contained"
        >
          <Typography
            fontSize="2.5rem"
            padding="0.5rem"
            sx={{
              fontWeight: 100,
            }}
          >
            Next
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
