import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
import { useSummarycontext } from "../../context/Summarycontext";
export function ConfirmationH() {
  const { summarystate } = useSummarycontext();
  const { gotoNext } = useMultiformcontext();
  const storevals = useGetQuestion();
  return (
    <>
      {/* <Box
        color="white"
        // bgcolor="rebeccapurple"
        position="relative"
        marginBottom={{ xs: "1.2rem", md: "2rem" }}
      >
        <div className="soed" />
        <Box padding="1rem">
          <Typography fontWeight="650" fontSize="1.2rem" marginBottom="0.4rem">
            Your monthly carbon footprint summary
          </Typography>
          <Typography fontWeight="650" fontSize="2rem" marginBottom="0.4rem">
            {Number(storevals.questionstate.totalemmision).toFixed(2)} kg CO
            <span>2</span>e
          </Typography>
          <Typography fontWeight="650" fontSize="1.2rem" marginBottom="2rem">
            Monthly footprint
          </Typography>
          <Box
            display="flex"
            gap={{ xs: "0.2rem", md: "1rem" }}
            flexWrap="wrap"
          >
            <Box>
              <Typography>Travel</Typography>
              <Box
                boxShadow="0 0 0 0.125rem black"
                padding="0.2rem"
                sx={{ borderRadius: "0.2rem" }}
              >
                {Number(summarystate.summarytravel).toFixed(2)}
              </Box>
            </Box>
            <Box>
              <Typography>Food</Typography>
              <Box
                boxShadow="0 0 0 0.125rem white"
                padding="0.2rem"
                sx={{ borderRadius: "0.2rem" }}
              >
                {Number(summarystate.summaryfood).toFixed(2)}
              </Box>
            </Box>
            <Box>
              <Typography>Home</Typography>
              <Box
                boxShadow="0 0 0 0.125rem white"
                padding="0.2rem"
                sx={{ borderRadius: "0.2rem" }}
              >
                {Number(summarystate.summaryhome).toFixed(2)}
              </Box>
            </Box>
            <Box>
              <Typography>Spending</Typography>
              <Box
                boxShadow="0 0 0 0.125rem white"
                padding="0.2rem"
                sx={{ borderRadius: "0.2rem" }}
              >
                {Number(summarystate.summaryspending).toFixed(2)}
              </Box>
            </Box>
          </Box>
          <Box marginTop="3rem">
            <Typography fontWeight="600" fontSize="1.4rem">
              Hyundai's Eco-friendly Initiatives
            </Typography>
            <Typography fontWeight="500" fontSize="1.2rem" marginBottom="1rem">
              Hyundai plant being a Smart factory, has the best eco-friendly
              manufacturing practices to conserve natural resources.
            </Typography>
            <Typography marginBottom="1rem">
              Torch bearers in Renewable Energy use : Hyundai India uses Wind,
              Solar, bagasse co-generation power.
            </Typography>
            <Typography marginBottom="1rem">
              Hyundai India has recently installed 10MW solar plant over the
              plant rooftop contributing 5% of Hyundai India's power
              consumption. Equivalent to sequestrating - 12,000 Tons of CO /
              Year or planting2 24,000 Trees).
            </Typography>
            <Typography marginBottom="1rem">
              95% of water requirement is garnered through recycled RO Water &
              Rain water harvesting.
            </Typography>
            <Typography>
              Our plant has 33% Green Cover i.e. 22,000+ Trees.
            </Typography>
          </Box>
          <Box display="none" marginTop="0.8rem">
            <Button hidden color="texted" onClick={gotoNext}>
              next
            </Button>
          </Box>
          <Box marginTop="32rem">
            <Typography fontSize="0.5rem">
              Hyundai doesn't take any ownership of the final results being
              calculated*
            </Typography>
          </Box>
        </Box>
      </Box> */}
      <Box
        // sx={{ aspectRatio: "1" }}
        width="100%"
        height="100%"
        position="relative"
      >
        {/* <div className="soed2" style={{ padding: "2rem" }}> */}
        <Box
          sx={{ aspectRatio: "9/16" }}
          // bgcolor="black"
          marginX="auto"
          height="100%"
          position="relative"
          display="flex"
        >
          <div className="soed3" />
          <Box paddingX="1.6rem" paddingTop="1rem">
            <Typography
              fontWeight="650"
              fontSize="1.6rem"
              marginBottom="0.4rem"
            >
              Your monthly carbon footprint summary
            </Typography>
            <Typography fontWeight="650" fontSize="1.6rem" marginBottom="2rem">
              {Number(storevals.questionstate.totalemmision).toFixed(2)} kg CO
              <sub>2</sub>e
            </Typography>
            <Typography fontWeight="650" fontSize="1.4rem" marginBottom="1rem">
              Monthly footprint
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              fontSize="2.2rem"
              fontWeight="lighter"
            >
              <Box>
                <Typography fontSize="2.2rem">Travel</Typography>
                <Box
                  boxShadow="0 0 0 0.125rem black"
                  padding="0.2rem"
                  sx={{ borderRadius: "0.2rem" }}
                >
                  {Number(summarystate.summarytravel).toFixed(2)}
                </Box>
              </Box>
              <Box>
                <Typography fontSize="2.2rem">Food</Typography>
                <Box
                  boxShadow="0 0 0 0.125rem black"
                  padding="0.2rem"
                  sx={{ borderRadius: "0.2rem" }}
                >
                  {Number(summarystate.summaryfood).toFixed(2)}
                </Box>
              </Box>
              <Box>
                <Typography fontSize="2.2rem">Home</Typography>
                <Box
                  boxShadow="0 0 0 0.125rem black"
                  padding="0.2rem"
                  sx={{ borderRadius: "0.2rem" }}
                >
                  {Number(summarystate.summaryhome).toFixed(2)}
                </Box>
              </Box>
              <Box>
                <Typography fontSize="2.2rem">Spending</Typography>
                <Box
                  boxShadow="0 0 0 0.125rem black"
                  padding="0.2rem"
                  sx={{ borderRadius: "0.2rem" }}
                >
                  {Number(summarystate.summaryspending).toFixed(2)}
                </Box>
              </Box>
            </Box>
            <Box marginTop="2.2rem">
              <Typography fontWeight="600" fontSize="2rem">
                Hyundai's Eco-friendly Initiatives
              </Typography>
              <Typography
                fontWeight="500"
                fontSize="1.8rem"
                marginBottom="1rem"
              >
                Hyundai plant being a Smart factory, has the best eco-friendly
                manufacturing practices to conserve natural resources.
              </Typography>
              <Typography fontSize="1.2rem" marginBottom="0.5rem">
                Torch bearers in Renewable Energy use : Hyundai India uses wind,
                solar, bagasse co-generation power.
              </Typography>
              <Typography fontSize="1.2rem" marginBottom="0.5rem">
                Hyundai India's plant has recently installed 10MW solar panels
                over the plant's rooftop contributing 5% of its power
                consumption. This is equivalent to sequestrating - 12,000 tons
                of CO2 / Year of planting 2,24,000 trees.
              </Typography>
              <Typography fontSize="1.2rem" marginBottom="0.5rem">
                95% of water requirement is gamered through recycled RO water
                and Rain water harvesting.
              </Typography>
              <Typography fontSize="1.2rem">
                Our plant has 33% Green Cover i.e. 22,000+ trees.
              </Typography>
            </Box>
            <Box display="none" marginTop="0.6rem">
              <Button hidden color="texted" onClick={gotoNext}>
                next
              </Button>
            </Box>
            <Box position="absolute" bottom="0">
              <Typography visibility="hidden" fontSize="0.8rem">
                Hyundai doesn't take any ownership of the final results being
                calculated*
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* </div> */}
      </Box>
    </>
  );
}
