import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  checkboxClasses,
  Typography,
} from "@mui/material";
import { useMultiformcontext } from "../context/Multiformcontext";
import { useGetQuestion } from "../context/Questioncontext";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import { useGetQuestion } from "../context/Questioncontext";
// import { useProtectioncontext } from "../context/Protectioncontext";
// import { useState } from "react";
// import { useCars } from "../context/Carcontext";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
export default function Twobuttons({ gotoNext, gotoPrev, state, lengthkeys }) {
  const { questiondispatch } = useGetQuestion();
  const { setState, state: curstate } = useMultiformcontext();

  // const [loader, setLoader] = useState(false);
  // async function submitFormvalues(params) {
  //   // console.log("loader isudhf sdoij");
  //   try {
  //     setLoader(true);
  //     // console.log({
  //     //   nameval: questionstate.cityname,
  //     //   countryname: questionstate.countryname,
  //     //   cityname: questionstate.cityname,
  //     //   transportarray: questionstate.transportarray,
  //     //   shortflights: questionstate.shortflights,
  //     //   longflights: questionstate.longflights,
  //     //   diet: questionstate.diet,
  //     //   members: questionstate.members,
  //     //   electricityconsumtion: questionstate.electricityconsumtion,
  //     //   spend: questionstate.spend,
  //     // });
  //   } catch (error) {
  //     toast.error("Please Try after some time!", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } finally {
  //     setLoader(false);
  //   }
  //   // console.log({
  //   //   emailvalue: values.email,
  //   //   phoneno: values.phone,
  //   //   nameval: values.name,
  //   // });
  // }
  // function setDisability(state) {
  //   if (state === 0) {
  //     return true;
  //   } else if (state < lengthkeys - 1) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
  return (
    <div style={{ backgroundColor: "#001e42" }} className="bottom_btnbar">
      <div className="bottom_btnbarcont">
        <Box paddingX={"2rem"} paddingY={"1rem"}>
          {curstate + 1 === 1 ? (
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Checkbox
                checked={true}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: "white",
                    transform: "scale(0.8)",
                  },
                }}
              />
              <Typography
                fontSize="1.4rem"
                color="white"
                marginBottom="0.25rem"
              >
                {/* *T&C apply. */}
                This calculator is developed by Lowsoot. The calculations shown
                here are just for entertainment purposes. Terms and conditions
                apply.*
              </Typography>
            </Box>
          ) : (
            <Typography fontSize="1rem" color="white" marginBottom="0.25rem">
              *T&C apply
            </Typography>
          )}
          <ButtonGroup
            sx={{ display: "flex", gap: "2rem" }}
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
          >
            {/* <Button
              sx={{ color: "primary.contrastText" }}
              disabled={state < 1 ? true : false}
              onClick={gotoPrev}
            >
              <ArrowUpwardIcon />
            </Button>
            {state !== lengthkeys - 1 && (
              <Button
                sx={{ color: "primary.contrastText" }}
                disabled={setDisability(state)}
                onClick={gotoNext}
              >
                <ArrowDownwardIcon />
              </Button>
            )}
            {state === lengthkeys - 1 && (
              <Button
                sx={{ color: "primary.contrastText" }}
                onClick={submitFormvalues}
                disabled={state === lengthkeys - 1 ? false : true}
              >
                Submit {loader && <CircularProgress color="spinme" />}
              </Button>
            )} */}
            {state + 1 > 1 && (
              <Button variant="contained" color="texted" onClick={gotoPrev}>
                <Typography
                  fontSize="2.5rem"
                  padding="0.5rem"
                  sx={{
                    fontWeight: 100,
                  }}
                >
                  Previous
                </Typography>
              </Button>
            )}
            {state !== 0 && (
              <Button
                variant="contained"
                color="texted"
                onClick={() => {
                  questiondispatch({ type: "RESET" });
                  setState(0);
                }}
              >
                <Typography
                  fontSize="2.5rem"
                  padding="0.5rem"
                  sx={{
                    fontWeight: 100,
                  }}
                >
                  Reset
                </Typography>
              </Button>
            )}
            {/* <Button variant="contained" color="texted" onClick={gotoNext}>
              next
            </Button> */}
          </ButtonGroup>
        </Box>
      </div>
    </div>
  );
}
