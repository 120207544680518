import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
// import { Subquestiontext, Questiontip } from "../Questiontext";

export function Confirmation1() {
  const { gotoNext } = useMultiformcontext();
  const storevals = useGetQuestion();
  return (
    <>
      {/* <Box position="relative"> */}
      <Box
        // bgcolor="yellowgreen"
        sx={{
          position: "fixed",
          top: "20%",
          // left: "50%",
          // transform: "translate(0, 0)",
          marginX: "auto",
        }}
      >
        <Box width="90%" marginX="auto">
          <Box
            // marginBottom={{ xs: "1.2rem", md: "2rem" }}
            marginBottom="6rem"
          >
            <Typography fontSize="4.8rem">
              Hey {storevals?.questionstate?.nameval},{/* fontWeight="light" */}
            </Typography>
          </Box>
          <Box
            marginBottom="6rem"
            // marginBottom={{ xs: "1.2rem", md: "3rem" }}
          >
            <Typography fontSize="4.8rem" lineHeight="5rem">
              Let's calculate your carbon footprint in few simple steps.
            </Typography>
          </Box>
          <Box
            // marginBottom="2rem"
            marginBottom="4rem"
            // marginBottom={{ xs: "0rem", md: "1rem" }}
          >
            <Typography
              fontSize="3.5rem"
              lineHeight="3rem"
              sx={{
                fontWeight: 100,
              }}
            >
              We'll go through the questions one category at a time.
            </Typography>
          </Box>
          <Box marginBottom="4rem">
            <Typography
              fontSize="3.5rem"
              lineHeight="3rem"
              sx={{
                fontWeight: 100,
              }}
            >
              Starting with eating habits
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={() => {
                gotoNext();
              }}
              type="submit"
              variant="contained"
            >
              <Typography
                fontSize="3.5rem"
                lineHeight="3rem"
                padding="0.5rem"
                sx={{
                  fontWeight: 100,
                }}
              >
                Let's go!
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
