import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGetQuestion } from "../../context/Questioncontext";
// import { Subquestiontext } from "../Questiontext";
// import { Questiontext, Questiontip } from "../Questiontext";
import { useMultiformcontext } from "../../context/Multiformcontext";

export function Emailform() {
  const { questiondispatch, questionstate } = useGetQuestion();
  const { gotoNext } = useMultiformcontext();
  const validationSchema = yup.object({
    name: yup
      .string("Enter your name")
      .required("Name is required")
      .min(1, "Please enter a valid name"),
    city: yup
      .string("Enter your city")
      .required("City name is required")
      .min(1, "Please enter a valid city name"),
    country: yup
      .string("Enter your country")
      .required("Country is required")
      .min(1, "Please enter a valid country name"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    phone: yup
      .string("Enter your phone number")
      .required("Phone number is required")
      .length(10),
  });
  const formik = useFormik({
    initialValues: {
      name: questionstate.nameval,
      city: questionstate.cityname,
      country: questionstate.countryname,
      email: questionstate.emailvalue,
      phone: questionstate.phonevalue,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      questiondispatch({
        type: "SET_MAILFORM",
        payload: {
          nameval: values.name,
          countryval: values.country,
          citynameval: values.city,
        },
      });
      questiondispatch({
        type: "SET_EMAIL",
        payload: {
          emailvalue: values.email,
        },
      });
      questiondispatch({
        type: "SET_PHONE",
        payload: {
          phonevalue: values.phone,
        },
      });
      gotoNext();
    },
  });
  return (
    <Box
      // bgcolor="cadetblue"
      width="100%"
      height="100%"
    >
      <Box
        sx={{
          transform: "translate(0, 10%)",
        }}
        // bgcolor="yellowgreen"
        marginX="auto"
        width="90%"
      >
        <form onSubmit={formik.handleSubmit}>
          <Typography fontSize="3rem">Name</Typography>
          <TextField
            fullWidth
            autoComplete="off"
            sx={{ marginBottom: "1rem", fontSize: "3rem" }}
            name="name"
            inputProps={{
              style: {
                fontSize: "3rem",
              },
            }}
            type="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <Typography fontSize="3rem">Phone number</Typography>
          <TextField
            inputProps={{
              style: {
                fontSize: "3rem",
              },
            }}
            autoComplete="off"
            fullWidth
            sx={{ marginBottom: "1rem", fontSize: "3rem" }}
            name="phone"
            type="number"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
          <Typography fontSize="3rem">Email</Typography>
          <TextField
            inputProps={{
              style: {
                fontSize: "3rem",
              },
            }}
            autoComplete="off"
            fullWidth
            sx={{ marginBottom: "1rem", fontSize: "3rem" }}
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Typography fontSize="3rem">Country</Typography>
          <TextField
            inputProps={{
              style: {
                fontSize: "3rem",
              },
            }}
            autoComplete="off"
            fullWidth
            sx={{ marginBottom: "1rem", fontSize: "3rem" }}
            name="country"
            type="text"
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
          <Typography fontSize="3rem">City</Typography>
          <TextField
            inputProps={{
              style: {
                fontSize: "3rem",
              },
            }}
            fullWidth
            autoComplete="off"
            sx={{ marginBottom: "1rem", fontSize: "3rem" }}
            name="city"
            type="text"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
          <Box marginTop="30%">
            <Button type="submit" variant="contained">
              <Typography fontSize="2rem">
                Find your carbon footprint
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
