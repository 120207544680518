import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
import {
  // Subquestiontext,
  // Questiontext,
  Confttext2,
  Confttext3,
  Conftextsuggestion,
} from "../Questiontext";
// import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import { useEffect, useState } from "react";
import axios from "axios";
export function Confirmation3() {
  const { gotoNext } = useMultiformcontext();
  const storevals = useGetQuestion();
  // console.log(storevals.nameval, storevals);
  const [text, setText] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;
    (async function () {
      try {
        setLoader(true);
        const response = await axios.post(
          "https://hundayi-calculator.yogeshpendse.repl.co/totalhome",
          {
            electricityconsumtion:
              storevals.questionstate.electricityconsumtion,
            members: storevals.questionstate.members,
          },
          { signal: signal }
        );
        setLoader(false);
        setText(response.data.totalhome);
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message);
        } else {
          console.log(error);
        }
      } finally {
        setLoader(false);
      }
    })();
    return () => {
      setLoader(false);
      controller.abort();
    };
  }, [
    storevals.questionstate.electricityconsumtion,
    storevals.questionstate.members,
  ]);
  function showmessage(numberval) {
    if (Number(numberval) < 15) {
      return "Using only what is needed saves a lot. A little more sustainability at home to reduce your emissions.";
    } else if (Number(numberval) > 14 && Number(numberval) < 25) {
      return "Using only what is needed saves a lot. A little more sustainability at home to reduce your emissions.";
    } else {
      return "That's a huge number! Some changes in your habits can bring your emissions down.";
    }
  }
  if (loader) {
    return (
      <>
        <CircularProgress />
      </>
    );
  }
  return (
    <>
      <Box>
        <Box marginBottom={{ xs: "1.2rem", md: "2rem" }}>
          <Confttext2 marginBottom="1rem" fontSize="1.2rem">
            Woah {storevals?.questionstate?.nameval},
          </Confttext2>
          {/* {text > 4 ? (
          <Typography fontSize="1.2rem">
            looks like you could be more sustainable at home
          </Typography>
        ) : (
          <Typography fontSize="1.2rem">
            looks like you're emissions just fine.
          </Typography>
        )} */}
          <Confttext2>{showmessage(text)}</Confttext2>
        </Box>
        <Box marginBottom={{ xs: "1.2rem", md: "3rem" }}>
          <Confttext3>Your emissions for the category home are:</Confttext3>
          <Confttext3>
            {Number(text).toFixed(2)} Kgs of CO<sub>2</sub>e/month
          </Confttext3>
        </Box>
        <Box marginBottom="1rem">
          {/* <Box
            position="relative"
            color="black"
            padding="0.5rem"
            boxShadow="0 0 0 0.1rem #c4c4c7"
            borderRadius="0.1rem"
            //   sx={{ boxShadow: "0 0 0 1rem black" }}
          >
            <Icon
              sx={{
                position: "absolute",
                top: "-1.4rem",
                left: "-0.5rem",
                transform: "rotate(180deg)",
              }}
            >
              <WbIncandescentIcon color="grayth" />
            </Icon>
            <Typography> */}
          <Conftextsuggestion>
            Using renewable electricity and composting your waste will help you
            live a more planet-friendly lifestyle.
          </Conftextsuggestion>
          {/* </Typography>
          </Box> */}
        </Box>

        <Button
          onClick={() => {
            gotoNext();
          }}
          type="submit"
          variant="contained"
        >
          <Typography
            fontSize="2.5rem"
            padding="0.5rem"
            sx={{
              fontWeight: 100,
            }}
          >
            Next up - Spending
          </Typography>
        </Button>
      </Box>
    </>
  );
}
