import { createContext, useContext, useReducer } from "react";

const Summaryprov = createContext();

export function Summarycontextprovider({ children }) {
  function questionreducer(state, action) {
    switch (action.type) {
      case "SET_SUMMARYSTART":
        // console.log(action);
        return {
          summarytravel: action.payload.summarytravel,
          summaryfood: action.payload.summaryfood,
          summaryhome: action.payload.summaryhome,
          summaryspending: action.payload.summaryspending,
        };
      default:
        throw new Error();
    }
  }
  const [summarystate, summarydispatch] = useReducer(questionreducer, {
    summarytravel: "",
    summaryfood: "",
    summaryhome: "",
    summaryspending: "",
  });
  return (
    <Summaryprov.Provider value={{ summarystate, summarydispatch }}>
      {children}
    </Summaryprov.Provider>
  );
}

export function useSummarycontext() {
  return useContext(Summaryprov);
}
