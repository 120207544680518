import { createContext, useContext, useState } from "react";
import { Confirmation0 } from "../components/confirmation/Confirmation0";
import { Confirmation1 } from "../components/confirmation/Confirmation1";
import { Confirmation2 } from "../components/confirmation/Confirmation2";
import { Confirmation3 } from "../components/confirmation/Confirmation3";
import { Confirmation4 } from "../components/confirmation/Confirmation4";
import { Confirmation5 } from "../components/confirmation/Confirmation5";
// import { Confirmationfinal } from "../components/confirmation/Confirmationfinal";
import { ConfirmationH } from "../components/confirmation/ConfirmationH";
import { ConfirmationT } from "../components/confirmation/ConfirmationT";
// import { Aptsize } from "../components/form/Aptsize";
// import { Bustravel } from "../components/form/Bustravel";
// import { Countryform } from "../components/form/Countryform";
import { Diet } from "../components/form/Diet";
// import { Railtravel } from "../components/form/Railtravel";
// import { Roadtravel } from "../components/form/Roadtravel";
import { Electricityconsumtion } from "../components/form/Electricityconsumtion";
import { Emailform } from "../components/form/Emailform";
// import { Getemail } from "../components/form/Getemail";
import { Homemembers } from "../components/form/Homemembers";
import { Longflights } from "../components/form/Longflights";
import { Moneyspent } from "../components/form/Moneyspent";
import { Shortflights } from "../components/form/Shortflights";
import { Typetransport } from "../components/form/Typetransport";
const Multiformprov = createContext();
export function Multiformprovider({ children }) {
  const [state, setState] = useState(0);
  const gotoNext = () => {
    setState((prevstate) => prevstate + 1);
  };
  const gotoPrev = () => {
    setState((prevstate) => prevstate - 1);
  };
  const comps = {
    // confirmationh: ConfirmationH,
    // confirmationfinal: Confirmationfinal,
    // confirmationh: ConfirmationH,
    confirmation0: Confirmation0,
    emailform: Emailform,
    confirmation1: Confirmation1,
    // typetransport: Typetransport,
    // shortflights: Shortflights,
    // longflights: Longflights,
    // confirmationt: ConfirmationT,
    diet: Diet,
    confirmation2: Confirmation2,
    homemembers: Homemembers,
    electricityconsumtion: Electricityconsumtion,
    confirmation3: Confirmation3,
    moneyspent: Moneyspent,
    confirmation4: Confirmation4,
    typetransport: Typetransport,
    shortflights: Shortflights,
    longflights: Longflights,
    confirmationt: ConfirmationT,
    // getemail: Getemail,
    confirmation5: Confirmation5,
    confirmationh: ConfirmationH,
    // confirmationfinal: Confirmationfinal,
    // getemail: Getemail,
    // aptsize: Aptsize,
    // roadtravel: Roadtravel,
    // bustravel: Bustravel,
    // railtravel: Railtravel,
    // countryform: Countryform,
  };
  const keys = Object.keys(comps);
  const lengthkeys = keys.length;
  const currentkey = keys[state];
  // const Currentcomp = comps?.[currentkey];
  const sideimagearray = [
    {
      id: "shortflights",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_plane.png?auto=format%2Ccompress&q=35",
      nameval: "short flights",
    },
    {
      id: "longflights",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_plane.png?auto=format%2Ccompress&q=35",
      nameval: "long flights",
    },
    {
      id: "aptsize",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_house.png?auto=format%2Ccompress&q=35",
      nameval: "apartment size",
    },
    {
      id: "roadtravel",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_car.png?auto=format%2Ccompress&q=35",
      nameval: "car travel",
    },
    {
      id: "bustravel",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_bus.png?auto=format%2Ccompress&q=35",
      nameval: "bus travel",
    },
    {
      id: "railtravel",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_rail.png?auto=format%2Ccompress&q=35",
      nameval: "rail travel",
    },
    {
      id: "electricityconsumtion",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_battery.png?auto=format%2Ccompress&q=35",
      nameval: "electricity",
    },
    {
      id: "diet",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_veggies.png?auto=format%2Ccompress&q=35",
      nameval: "diet",
    },
    {
      id: "countryform",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_earth.png?auto=format%2Ccompress&q=35",
      nameval: "country",
    },
    {
      id: "emailform",
      imageurl:
        "https://projectwren.imgix.net/calculator-icons/cloud_finish_flag.png?auto=format%2Ccompress&q=35",
      nameval: "Information",
    },
  ];
  const sidebararray = [...keys]
    .map((itm) => [...sideimagearray].find((imageitm) => imageitm.id === itm))
    .map((item, index) => {
      return { ...item, index };
    });
  return (
    <Multiformprov.Provider
      value={{
        setState,
        state,
        gotoPrev,
        gotoNext,
        comps,
        keys,
        lengthkeys,
        currentkey,
        sidebararray,
      }}
    >
      {children}
    </Multiformprov.Provider>
  );
}
export function useMultiformcontext() {
  return useContext(Multiformprov);
}
