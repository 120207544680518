import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
import { Questiontext4k } from "../Questiontext";
// import { Questiontext } from "../Questiontext";

export function Diet() {
  const { questionstate, questiondispatch } = useGetQuestion();
  const { gotoNext } = useMultiformcontext();
  const state = [
    { id: 1, text: "Vegan", value: "vegan" },
    { id: 2, text: "Vegetarian", value: "veg" },
    { id: 3, text: "Non-Vegetarian", value: "nonveg" },
  ];
  return (
    <Box position="absolute" top="20%" width="90%">
      <Box>
        <Box>
          {/* <Box
          sx={{
            display: { md: "none" },
            width: { xs: "7rem", md: "10rem" },
          }}
        >
          <img
            className="fullimagewidth"
            src="https://projectwren.imgix.net/calculator-icons/cloud_veggies.png?auto=format%2Ccompress&q=35"
            alt="logoimage"
          />
        </Box> */}
          {/* <Typography fontWeight="bold" fontSize="3rem"> */}
          <Questiontext4k>My dietary lifestyle is:</Questiontext4k>
          {/* </Typography> */}
        </Box>
        <Box
          marginBottom="7rem"
          display="flex"
          flexDirection="column"
          gap="1rem"
        >
          {state.map((item) => (
            <Box
              key={item.id}
              onClick={() => {
                questiondispatch({
                  type: "SET_DIET",
                  payload: { diet: item.value },
                });
              }}
              // fontSize="2.8rem"
              sx={{
                fontSize: "2.2rem",
                fontWeight: "lighter",
                paddingX: "2rem",
                paddingY: "1.2rem",
                border: 1,
                cursor: "pointer",
                borderColor: "#000",
                backgroundColor:
                  questionstate.diet === item.value ? "#b3d6ff" : "inherit",
              }}
            >
              {item.text}
            </Box>
          ))}
        </Box>
      </Box>
      <Box marginTop="10rem">
        <Button
          onClick={() => {
            gotoNext();
          }}
          type="submit"
          variant="contained"
          sx={{ padding: "1rem" }}
        >
          <Typography fontSize="2rem">Next</Typography>
        </Button>
      </Box>
    </Box>
  );
}
