import { createContext, useContext, useReducer } from "react";

const GetQuestionprov = createContext();

export function GetQuestioncontextprovider({ children }) {
  function questionreducer(state, action) {
    switch (action.type) {
      case "SET_SHORT_FLIGHT":
        return { ...state, shortflights: action.payload.shortflights };
      case "SET_LONG_FLIGHT":
        return { ...state, longflights: action.payload.longflights };
      case "SET_BUS_TRAVEL":
        return { ...state, bustravel: action.payload.bustravel };
      case "SET_RAIL_TRAVEL":
        return { ...state, railwaytravel: action.payload.railwaytravel };
      case "SET_ROAD_TRAVEL":
        return { ...state, roadtravel: action.payload.roadtravel };
      case "SET_APT_SIZE":
        return { ...state, aptsize: action.payload.aptsize };
      case "SET_ELECTRICITY_CONSUMPTION":
        return {
          ...state,
          electricityconsumtion: action.payload.electricityconsumtion,
        };
      case "SET_GAS_CONSUMPTION":
        return {
          ...state,
          gasconsumption: action.payload.gasconsumption,
        };

      case "SET_DIET":
        return {
          ...state,
          diet: action.payload.diet,
        };
      case "SET_COUNTRY":
        return {
          ...state,
          country: action.payload.country,
        };
      case "SET_CITY":
        return {
          ...state,
          cityname: action.payload.city,
        };
      case "SET_MAILFORM":
        return {
          ...state,
          emailvalue: action.payload.emailvalue,
          phoneno: action.payload.phoneno,
          nameval: action.payload.nameval,
          cityname: action.payload.citynameval,
          countryname: action.payload.countryval,
        };
      case "SET_EMAIL":
        return {
          ...state,
          emailvalue: action.payload.emailvalue,
        };
      case "SET_MEMBERS":
        return {
          ...state,
          members: action.payload.members,
        };
      case "SET_MONEYSPENT":
        return {
          ...state,
          spend: action.payload.spend,
        };
      case "SET_TRANSPORTARRAY":
        return {
          ...state,
          transportarray: action.payload.transportarray,
        };
      case "SET_TOTALEMMISON":
        return {
          ...state,
          totalemmision: action.payload.totalemmision,
        };
      case "SET_PHONE":
        return { ...state, phonevalue: action.payload.phonevalue };
      case "RESET":
        return {
          shortflights: "0",
          longflights: "0",
          bustravel: "0",
          railwaytravel: "0",
          aptsize: "0",
          members: 1,
          electricityconsumtion: "300",
          gasconsumption: "0",
          diet: "veg",
          countryname: "",
          country: "IN",
          roadtravel: "0",
          cityname: "",
          emailvalue: "",
          phoneno: "",
          nameval: "",
          spend: "500",
          transportarray: [
            {
              id: 1,
              text: "Four Wheeler",
              value: "500",
              isChecked: false,
            },
            {
              id: 2,
              text: "Two Wheeler",
              value: "3500",
              isChecked: false,
            },
            { id: 3, text: "Cab", value: "7500", isChecked: false },
            {
              id: 4,
              text: "Public Transport",
              value: "10",
              isChecked: false,
            },
            {
              id: 5,
              text: "Cycling or Walking",
              value: "10",
              isChecked: false,
            },
          ],
          totalemmision: 0,
          phonevalue: "",
        };
      default:
        throw new Error();
    }
  }
  const [questionstate, questiondispatch] = useReducer(questionreducer, {
    shortflights: "0",
    longflights: "0",
    bustravel: "0",
    railwaytravel: "0",
    aptsize: "0",
    members: 1,
    electricityconsumtion: "300",
    gasconsumption: "0",
    diet: "veg",
    countryname: "",
    country: "IN",
    roadtravel: "0",
    cityname: "",
    emailvalue: "",
    phoneno: "",
    nameval: "",
    spend: "500",
    transportarray: [
      {
        id: 1,
        text: "Four Wheeler",
        value: "500",
        isChecked: false,
      },
      {
        id: 2,
        text: "Two Wheeler",
        value: "3500",
        isChecked: false,
      },
      { id: 3, text: "Cab", value: "7500", isChecked: false },
      {
        id: 4,
        text: "Public Transport",
        value: "10",
        isChecked: false,
      },
      {
        id: 5,
        text: "Cycling or Walking",
        value: "10",
        isChecked: false,
      },
    ],
    totalemmision: 0,
    phonevalue: "",
  });
  return (
    <GetQuestionprov.Provider value={{ questionstate, questiondispatch }}>
      {children}
    </GetQuestionprov.Provider>
  );
}

export function useGetQuestion() {
  return useContext(GetQuestionprov);
}
