import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
import {
  // Subquestiontext,
  // Questiontext,
  Conftextsuggestion,
  Confttext3,
  Confttext2,
} from "../Questiontext";
// import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSummarycontext } from "../../context/Summarycontext";
export function ConfirmationT() {
  const { gotoNext } = useMultiformcontext();
  const [state, setState] = useState(false);
  const { questiondispatch } = useGetQuestion();
  const storevals = useGetQuestion();
  const [text, setText] = useState(false);
  const [loader, setLoader] = useState(false);
  const { summarydispatch } = useSummarycontext();
  const { questionstate } = useGetQuestion();
  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;
    (async function () {
      try {
        setLoader(true);
        const response = await axios.post(
          "https://hundayi-calculator.yogeshpendse.repl.co/totaltravel",
          {
            transportarray: storevals.questionstate.transportarray,
            longflights: storevals.questionstate.longflights,
            shortflights: storevals.questionstate.shortflights,
          },
          { signal: signal }
        );
        setLoader(false);
        setText(response.data.totaltravel);
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message);
        } else {
          console.log(error);
        }
      } finally {
        setLoader(false);
      }
    })();
    return () => {
      setLoader(false);
      controller.abort();
    };
  }, [
    storevals.questionstate.longflights,
    storevals.questionstate.shortflights,
    storevals.questionstate.transportarray,
  ]);
  // console.log(storevals.nameval, storevals);
  if (loader) {
    return (
      <>
        <CircularProgress />
      </>
    );
  }
  function showmessage(numberval) {
    if (Number(numberval) < 151) {
      return "Keep it up! Your travel emissions are not too heavy.";
    } else if (Number(numberval) > 150 && Number(numberval) < 301) {
      return "Hey, it seems you like traveling. Small efforts can be done to reduce your carbon emission.";
    } else {
      return "You're a seasoned traveler! Little efforts can help cut down on the carbon emissions.";
    }
  }
  async function gettotalemmison() {
    try {
      const response = await axios.post(
        "https://hundayi-calculator.yogeshpendse.repl.co/totalemmission",
        {
          nameval: questionstate.nameval,
          countryname: questionstate.countryname,
          cityname: questionstate.cityname,
          shortflights: questionstate.shortflights,
          longflights: questionstate.longflights,
          diet: questionstate.diet,
          members: questionstate.members,
          electricityconsumtion: questionstate.electricityconsumtion,
          spend: questionstate.spend,
          emailvalue: questionstate.emailvalue,
          transportarray: questionstate.transportarray,
          phonevalue: questionstate.phonevalue,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return { total: 0 };
    }
  }
  async function handleclick(params) {
    setState(true);
    const restot = await gettotalemmison();
    questiondispatch({
      type: "SET_TOTALEMMISON",
      payload: {
        totalemmision: restot.total,
      },
    });
    summarydispatch({
      type: "SET_SUMMARYSTART",
      payload: {
        summarytravel: restot.totaltravel,
        summaryfood: restot.totalfood,
        summaryhome: restot.totalhome,
        summaryspending: restot.totalspend,
      },
    });
    gotoNext();
    setState(false);
  }
  return (
    <>
      <Box>
        <Box marginBottom={{ xs: "1.2rem", md: "2rem" }}>
          <Confttext2 marginBottom="1rem" fontSize="1.2rem">
            Woah {storevals?.questionstate?.nameval},
          </Confttext2>
          <Confttext2 fontSize="1.2rem">{showmessage(text)}</Confttext2>
          {/* <Typography fontSize="1.2rem">
          Hey, it seems you like traveling. Small efforts can be done to reduce
          your carbon emission.
        </Typography>
        <Typography fontSize="1.2rem">
          Your travel emissions are extremely hard on the planet
        </Typography> */}
        </Box>
        <Box marginBottom={{ xs: "1.2rem", md: "3rem" }}>
          <Confttext3>Your emissions for the category travel are:</Confttext3>
          <Confttext3>
            {Number(text).toFixed(2)} Kgs of CO<sub>2</sub>e/month
          </Confttext3>
        </Box>
        <Box marginBottom="1rem">
          {/* <Box
            position="relative"
            color="black"
            padding="0.5rem"
            boxShadow="0 0 0 0.1rem #c4c4c7"
            borderRadius="0.1rem"
            //   sx={{ boxShadow: "0 0 0 1rem black" }}
          >
            <Icon
              sx={{
                position: "absolute",
                top: "-1.4rem",
                left: "-0.5rem",
                transform: "rotate(180deg)",
              }}
            >
              <WbIncandescentIcon color="grayth" />
            </Icon>
            <Typography> */}
          <Conftextsuggestion>
            Using Public transport and electric vehicles will help you reduce
            your footprint.
          </Conftextsuggestion>
          {/* </Typography>
          </Box> */}
        </Box>

        <Button onClick={handleclick} type="submit" variant="contained">
          <Typography
            fontSize="2.5rem"
            padding="0.5rem"
            sx={{
              fontWeight: 100,
            }}
          >
            Next
            {state && <CircularProgress color="inherit" />}
          </Typography>
        </Button>
      </Box>
    </>
  );
}
