import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
import { Questiontext4k } from "../Questiontext";

export function Typetransport() {
  // I use these modes of transportation
  const { questionstate, questiondispatch } = useGetQuestion();
  const { gotoNext } = useMultiformcontext();
  const [state, setState] = useState(questionstate.transportarray);
  function handletransport(state) {
    questiondispatch({
      type: "SET_TRANSPORTARRAY",
      payload: { transportarray: state },
    });
    gotoNext();
  }

  return (
    <Box position="absolute" top="20%">
      <Box>
        <Box>
          <Box>
            {/* <Box
          sx={{
            display: { md: "none" },
            width: { xs: "7rem", md: "10rem" },
          }}
        >
          <img
            className="fullimagewidth"
            src="https://projectwren.imgix.net/calculator-icons/cloud_plane.png?auto=format%2Ccompress&q=35"
            alt="logoimage"
          />
        </Box> */}
            <Questiontext4k>
              I use these modes of transportation:
            </Questiontext4k>
            {/* <Questiontip>
          Count any flights longer than 3 hours or much greater than 1000km,
          like flying from Delhi to Mumbai.
        </Questiontip> */}
          </Box>
          <Box
            marginBottom="7rem"
            display="flex"
            flexDirection="column"
            gap="1rem"
          >
            {state.map((item) => (
              <Box
                key={item.id}
                onClick={() => {
                  setState((currstate) =>
                    currstate.map((itm) => {
                      if (itm.id === item.id) {
                        return { ...itm, isChecked: !itm.isChecked };
                      } else {
                        return itm;
                      }
                    })
                  );
                }}
                sx={{
                  // paddingX: { xs: "1rem", md: "2rem" },
                  // paddingY: { xs: "0.5rem", md: "1rem" },
                  fontSize: "2.2rem",
                  fontWeight: "lighter",
                  paddingX: "2rem",
                  paddingY: "1.2rem",
                  border: 1,
                  cursor: "pointer",
                  borderColor: "#000",
                  //   isChecked
                  backgroundColor: item.isChecked ? "#b3d6ff" : "inherit",
                }}
              >
                {item.text}
              </Box>
            ))}
          </Box>
        </Box>
        <Box marginTop="10rem">
          <Button
            onClick={() => handletransport(state)}
            type="submit"
            variant="contained"
          >
            <Typography
              fontSize="2.5rem"
              padding="0.5rem"
              sx={{
                fontWeight: 100,
              }}
            >
              Next
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
