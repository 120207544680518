import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
import {
  // Subquestiontext,
  // Questiontext,
  Confttext2,
  Confttext3,
  Conftextsuggestion,
} from "../Questiontext";
// import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import { useEffect, useState } from "react";
import axios from "axios";
// import { useSummarycontext } from "../../context/Summarycontext";
// import { useSummarycontext } from "../../context/Summarycontext";
export function Confirmation4() {
  const { gotoNext } = useMultiformcontext();
  // const [state, setState] = useState(false);
  const storevals = useGetQuestion();
  // const { questionstate } = useGetQuestion();

  // const { summarydispatch } = useSummarycontext();
  // console.log(storevals.nameval, storevals);
  const [text, setText] = useState(false);
  // const { questiondispatch } = useGetQuestion();
  const [loader, setLoader] = useState(false);
  // const { summarydispatch } = useSummarycontext();
  // async function gettotalemmison() {
  //   try {
  //     const response = await axios.post(
  //       "https://hundayi-calculator.yogeshpendse.repl.co/totalemmission",
  //       {
  //         nameval: questionstate.nameval,
  //         countryname: questionstate.countryname,
  //         cityname: questionstate.cityname,
  //         shortflights: questionstate.shortflights,
  //         longflights: questionstate.longflights,
  //         diet: questionstate.diet,
  //         members: questionstate.members,
  //         electricityconsumtion: questionstate.electricityconsumtion,
  //         spend: questionstate.spend,
  //         emailvalue: questionstate.emailvalue,
  //         transportarray: questionstate.transportarray,
  //       }
  //     );
  //     if (response.status === 200) {
  //       return response.data;
  //     }
  //   } catch (error) {
  //     return { total: 0 };
  //   }
  // }
  async function handleclick(params) {
    // setState(true);
    // const restot = await gettotalemmison();
    // questiondispatch({
    //   type: "SET_TOTALEMMISON",
    //   payload: {
    //     totalemmision: restot.total,
    //   },
    // });
    // summarydispatch({
    //   type: "SET_SUMMARYSTART",
    //   payload: {
    //     summarytravel: restot.totaltravel,
    //     summaryfood: restot.totalfood,
    //     summaryhome: restot.totalhome,
    //     summaryspending: restot.totalspend,
    //   },
    // });
    gotoNext();
    // setState(false);
  }
  useEffect(() => {
    let controller = new AbortController();
    const signal = controller.signal;
    (async function () {
      try {
        // SET_SUMMARYSTART
        setLoader(true);
        const response = await axios.get(
          `https://hundayi-calculator.yogeshpendse.repl.co/totalspend/${storevals.questionstate.spend}`,
          { signal: signal }
        );
        setLoader(false);
        setText(response.data.totalspend);
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message);
        } else {
          console.log(error);
        }
      } finally {
        setLoader(false);
      }
    })();
    return () => {
      setLoader(false);
      controller.abort();
    };
  }, [storevals.questionstate.spend]);
  function showmessage(numberval) {
    if (Number(numberval) < 501) {
      return "That's great! You’re doing it right.";
    } else if (Number(numberval) > 500 && Number(numberval) < 5001) {
      return "Like spending, huh? Try bringing it down to improve your score.";
    } else {
      return "Shopping too much? A few efforts can go a long way to have reduced emissions.";
    }
  }
  if (loader) {
    return (
      <>
        <CircularProgress />
      </>
    );
  }
  return (
    <>
      <Box>
        <Box marginBottom={{ xs: "1.2rem", md: "2rem" }}>
          <Confttext2 marginBottom="1rem" fontSize="1.2rem">
            Woah {storevals?.questionstate?.nameval},
          </Confttext2>
          <Confttext2 fontSize="1.2rem">
            {showmessage(storevals.questionstate.spend)}
          </Confttext2>
        </Box>
        <Box marginBottom={{ xs: "1.2rem", md: "3rem" }}>
          <Confttext3>Your emissions for the category spending are:</Confttext3>
          <Confttext3>
            {Number(text).toFixed(2)} Kgs of CO<sub>2</sub>e/month
          </Confttext3>
        </Box>
        <Box marginBottom="1rem">
          {/* <Box
            position="relative"
            color="black"
            padding="0.5rem"
            boxShadow="0 0 0 0.1rem #c4c4c7"
            borderRadius="0.1rem"
            //   sx={{ boxShadow: "0 0 0 1rem black" }}
          >
            <Icon
              sx={{
                position: "absolute",
                top: "-1.4rem",
                left: "-0.5rem",
                transform: "rotate(180deg)",
              }}
            >
              <WbIncandescentIcon color="grayth" />
            </Icon>
            <Typography> */}
          <Conftextsuggestion>
            A minimalist lifestyle always helps our planet.
          </Conftextsuggestion>
          {/* </Typography>
          </Box> */}
        </Box>

        <Button onClick={handleclick} type="submit" variant="contained">
          <Typography
            fontSize="2.5rem"
            padding="0.5rem"
            sx={{
              fontWeight: 100,
            }}
          >
            Next up - Travel
            {/* {state && <CircularProgress color="inherit" />} */}
          </Typography>
        </Button>
      </Box>
    </>
  );
}
