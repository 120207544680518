import { Box, Icon, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
export function Questiontext({ children }) {
  const matches = useMediaQuery("(min-width:768px)");
  const fontsize = matches ? "1.75rem" : "1.625rem";
  const lineHeight = matches ? "2.063rem" : "1.875rem";
  const stylesforques = {
    fontSize: fontsize,
    lineHeight: lineHeight,
  };
  return (
    <Typography
      fontSize={stylesforques.fontSize}
      variant="h5"
      lineHeight={stylesforques.lineHeight}
      fontWeight="600"
      gutterBottom
    >
      {children}
    </Typography>
  );
}
export function Questiontext4k({ children }) {
  // const matches = useMediaQuery("(min-width:768px)");
  // const fontsize = matches ? "1.75rem" : "1.625rem";
  // const lineHeight = matches ? "2.063rem" : "1.875rem";
  // const stylesforques = {
  //   fontSize: fontsize,
  //   lineHeight: lineHeight,
  // };
  return (
    <Typography
      fontSize="2.2rem"
      variant="h5"
      // lineHeight="2rem"
      fontWeight="bold"
      gutterBottom
    >
      {children}
    </Typography>
  );
}
export function Questiontip({ children }) {
  const matches = useMediaQuery("(min-width:768px)");
  const fontsize = matches ? "1rem" : "0.938rem";
  const lineHeight = matches ? "1.4" : "1.4";
  const stylesforques = {
    fontSize: fontsize,
    lineHeight: lineHeight,
  };
  return (
    <Typography
      variant="body1"
      lineHeight={stylesforques.lineHeight}
      fontSize={stylesforques.fontSize}
      fontWeight="400"
      sx={{ opacity: 0.8 }}
      marginBottom="2rem"
    >
      {children}
    </Typography>
  );
}

// Typography fontSize="4.8rem" lineHeight="5rem"

export function Confttext3({ children }) {
  return (
    <Typography fontSize="4.2rem" lineHeight="5rem" gutterBottom>
      {children}
    </Typography>
  );
}

export function Confttext2({ children }) {
  return (
    <Typography fontSize="3rem" gutterBottom>
      {children}
    </Typography>
  );
}

export function Conftextsuggestion({ children }) {
  return (
    <Box
      position="relative"
      color="black"
      padding="0.5rem"
      boxShadow="0 0 0 0.1rem #c4c4c7"
      borderRadius="0.1rem"
      //   sx={{ boxShadow: "0 0 0 1rem black" }}
    >
      <Icon
        sx={{
          position: "absolute",
          top: "-1.4rem",
          left: "-0.5rem",
          transform: "rotate(180deg)",
        }}
      >
        <WbIncandescentIcon color="grayth" />
      </Icon>

      <Typography fontSize="2.5rem">{children}</Typography>
    </Box>
  );
}

export function Subquestiontext({ children }) {
  const matches = useMediaQuery("(min-width:768px)");
  const fontsize = matches ? "1.6rem" : "1.4rem";
  const lineHeight = matches ? "1.6rem" : "1.5rem";
  const stylesforques = {
    fontSize: fontsize,
    lineHeight: lineHeight,
  };
  return (
    <Typography
      fontSize={stylesforques.fontSize}
      variant="h5"
      lineHeight={stylesforques.lineHeight}
      fontWeight="600"
      gutterBottom
    >
      {children}
    </Typography>
  );
}
