import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useMultiformcontext } from "../../context/Multiformcontext";

export function Confirmation0() {
  const { gotoNext } = useMultiformcontext();

  return (
    <>
      <Box
        // sx={{ aspectRatio: "1" }}

        color="white"
        width="100%"
        height="100%"
        position="relative"
      >
        {/* <div className="soed2" style={{ padding: "2rem" }}> */}
        <Box
          sx={{ aspectRatio: "9/16" }}
          // bgcolor="black"
          marginX="auto"
          height="100%"
          position="relative"
          display="flex"
        >
          <div className="soed2" />
          <Box marginX="auto" width="90%">
            <Box
              // border="0.2rem solid black"
              position="relative"
              // marginBottom="2.4rem"
              sx={{
                transform: "translate(0, 30%)",
              }}
            >
              <Typography
                // fontSize={{ xs: "1.4rem", md: "2.4rem" }}
                fontSize="4.8rem"
                fontWeight="500"
                marginBottom="2rem"
              >
                Welcome to the Carbon Footprint Calculator
              </Typography>
              <Typography
                // fontSize={{ xs: "1rem", md: "1.4rem" }}
                fontSize="2.8rem"
                marginBottom="14rem"
              >
                Answer a few questions to learn about your carbon footprint.
              </Typography>
              <Box>
                <Button
                  sx={{
                    fontSize: "2.4rem",
                  }}
                  onClick={() => gotoNext()}
                  variant="contained"
                >
                  Let's go!
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* </div> */}
      </Box>
    </>
  );
}
