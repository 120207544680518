import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMultiformcontext } from "../../context/Multiformcontext";
import { useGetQuestion } from "../../context/Questioncontext";
import {
  // Subquestiontext, Questiontext,
  Confttext3,
} from "../Questiontext";
// import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
export function Confirmation5() {
  const { gotoNext } = useMultiformcontext();
  const storevals = useGetQuestion();
  // console.log(storevals.nameval, storevals);
  return (
    <>
      <Box>
        <Box marginBottom={{ xs: "1.2rem", md: "2rem" }}>
          <Confttext3>Your personal monthly carbon emissions are</Confttext3>
          <Confttext3>
            {Number(storevals.questionstate.totalemmision).toFixed(2)}
            KG
          </Confttext3>
          {/* <Typography fontSize="1.2rem">
          You fight climate change more by spending less.
        </Typography>
        <Typography fontSize="1.2rem">
          If everyone lived like you for a month it would be the equivalent of
          cutting down
        </Typography>
        <Typography fontSize="1.2rem" fontWeight="700">
          {Math.round(storevals.questionstate.totalemmision / 1.6)} trees every
          second!
        </Typography> */}
        </Box>

        <Box marginBottom="2rem">
          {/* <Button
          //   onClick={() => {
          //     gotoNext();
          //   }}
          target="_blank"
          href="https://www.instamojo.com/@lowsoot_official/l2f5fbdd27e464b7187fa06b6369a4370"
          type="submit"
          variant="contained"
        >
          Take action now
        </Button> */}
        </Box>
        <Box>
          <Button
            onClick={() => {
              gotoNext();
            }}
            type="submit"
            variant="contained"
          >
            <Typography
              fontSize="2.5rem"
              padding="0.5rem"
              sx={{
                fontWeight: 100,
              }}
            >
              View Results
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}
